import HTML_404 from "@/components/404.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import { isUsuarioAutenticado, possuiPermissao } from "../seguranca/helpers";
import TelaLogin from "../views/TelaLogin";
import EstiloPublico from "../views/layouts/Publico";
import SemPermissao from "../views/layouts/SemPermissao";


Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "basico" */'../views/layouts/Logado'),
        children: [
            {
                path: '/home',
                component: () => import(/* webpackChunkName: "basico" */'../views/Home.vue'),
            },
            // {
            //     path: '/dae',
            //     component: () =>
            //         import(/* webpackChunkName: "dae" */ '../views/dae/create'),
            // },

            // ROTAS DE CONSULTA DE DAE
            {
                path: '/dae-consulta',
                component: () => import(/* webpackChunkName: "dae" */ '../views/dae/index')
            },
            {
                path: '/dae-consulta/:dae/view',
                component: () => import(/* webpackChunkName: "dae" */ '../views/dae/visualizar')
            },
            // FIM DE ROTAS DE CONSULTA DE DAE

            {
                path: '/alvara',
                component: () => import(/* webpackChunkName: "alvara" */ '../views/alvara'),
            },
            {
                path: '/alvara/dae-automatico/create',
                component: () => import(/* webpackChunkName: "alvara" */ "../views/alvara/daeAutomatico"),
            },
            {
                path: '/alvara/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "alvara" */ "../views/alvara/daeAutomatico"),
            },
            {
                path: '/alvara/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "alvara" */ "../views/alvara/daeAutomatico"),
            },
            {
                path: "/alvara/dae-manual/create",
                component: () => import(/* webpackChunkName: "alvara" */ "../views/alvara/daeManual"),
            },
            {
                path: "/alvara/dae-manual/:id/edit",
                component: () => import(/* webpackChunkName: "alvara" */ "../views/alvara/daeManual"),
            },
            {
                path: "/alvara/dae-manual/:id/view",
                component: () => import(/* webpackChunkName: "alvara" */ "../views/alvara/daeManual"),
            },
            {
                path: "/alvara/isencao/create",
                component: () => import(/* webpackChunkName: "alvara"*/ "../views/alvara/isencao"),
            },
            {
                path: '/alvara/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "alvara" */ "../views/alvara/isencao"),
            },
            {
                path: '/alvara/isencao/:id/view',
                component: () => import(/* webpackChunkName: "alvara" */ "../views/alvara/isencao"),
            },
            {
                path: '/vistoria',
                component: () => import(/* webpackChunkName: "vistoria" */ '../views/vistoriador/vistoria'),
            },
            {
                path: '/vistoria/dae-automatico/create',
                component: () => import(/* webpackChunkName: "vistoria" */ '../views/vistoriador/vistoria/DaeAutomatico'),
            },
            {
                path: '/vistoria/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "vistoria" */ '../views/vistoriador/vistoria/DaeAutomatico'),
            },
            {
                path: '/vistoria/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "vistoria" */ '../views/vistoriador/vistoria/DaeAutomatico'),
            },
            {
                path: '/vistoria/dae-manual/create',
                component: () => import(/* webpackChunkName: "vistoria" */ '../views/vistoriador/vistoria/daeManual'),
            },
            {
                path: '/vistoria/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "vistoria" */ '../views/vistoriador/vistoria/daeManual'),
            },
            {
                path: '/vistoria/dae-manual/:id/view',
                component: () => import(/* webpackChunkName: "vistoria" */ '../views/vistoriador/vistoria/daeManual'),
            },
            {
                path: '/vistoria/isencao/create',
                component: () => import(/* webpackChunkName: "vistoria" */ '../views/vistoriador/vistoria/isencao'),
            },
            {
                path: '/vistoria/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "vistoria" */ '../views/vistoriador/vistoria/isencao'),
            },
            {
                path: '/vistoria/isencao/:id/view',
                component: () => import(/* webpackChunkName: "vistoria" */ '../views/vistoriador/vistoria/isencao'),
            },
            // Rotas de Pessoa Física
            {
                path: '/outros',
                component: () => import(/* webpackChunkName: "outros" */ '../views/pessoa-fisica/mosaico'),
            },
            {
                path: '/pessoa-fisica',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica'),
            },
            {
                path: '/pessoa-fisica/create',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/create'),
            },
            {
                path: '/pessoa-fisica/:id/edit',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/create'),
            },
            {
                path: '/pessoa-fisica/:id/view',
                component: () =>
                    import(
                        /* webpackChunkName: "registro" */ '../views/pessoa-fisica/create'
                    ),
            },
            // Fim de Rotas de Pessoa Física
            // Rotas de Licença de Eventos de Pessoa Física
            {
                path: '/pessoa-fisica/licenca',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/licenca'),
            },
            {
                path: '/pessoa-fisica/licenca/dae-automatico/create',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/licenca/daeAutomatico'),
            },
            {
                path: '/pessoa-fisica/licenca/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/licenca/daeAutomatico'),
            },
            {
                path: '/pessoa-fisica/licenca/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/licenca/daeAutomatico'),
            },
            {
                path: '/pessoa-fisica/licenca/dae-manual/create',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/licenca/daeManual'),
            },
            {
                path: '/pessoa-fisica/licenca/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/licenca/daeManual'),
            },
            {
                path: '/pessoa-fisica/licenca/dae-manual/:id/view',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/licenca/daeManual'),
            },
            {
                path: '/pessoa-fisica/licenca/isencao/create',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/licenca/isencao'),
            },
            {
                path: '/pessoa-fisica/licenca/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/licenca/isencao'),
            },
            {
                path: '/pessoa-fisica/licenca/isencao/:id/view',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/licenca/isencao'),
            },
          // Fim de Rotas de Licença de Eventos de Pessoa Física

          //Rotas para pessoa juridica não controlada pela DPA

          {
            path: '/estabelecimento-nao-controlado',
            component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/estabelecimento-nao-controlado/index'),
          },

          {
            path: '/estabelecimento-nao-controlado/create',
            component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/estabelecimento-nao-controlado/create'),
          },

          {
            path: '/estabelecimento-nao-controlado/:id/edit',
            component: () => import(/* webpackChunkName: "estabelecimento-nao-controlado" */ '../views/estabelecimento-nao-controlado/create'),
          },

          {
            path: '/estabelecimento-nao-controlado/dados-gerais/:id',
            component: () => import(/* webpackChunkName: "pessoa-juridica" */ '../views/estabelecimento-nao-controlado/dadosGerais'),
        },

        {
          path: '/estabelecimento-nao-controlado',
          component: () => import(/* webpackChunkName: "pessoa-juridica" */ '../views/pessoa-juridica/mosaico'),
      },

          //fim das rotas para pessoa juridica não controlada pela DPA
            // Fim de Rotas de Licença de Eventos de Pessoa Física

            // início de rotas de licença de eventos para pessoas jurídicas não monitoradas

            {
                path: '/estabelecimento-nao-controlado/licenca-eventos',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '../views/estabelecimento-nao-controlado/licenca-nao-controlada'),
            },

            {
                path: '/estabelecimento-nao-controlado/licenca-eventos/isencao/create',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '@/views/estabelecimento-nao-controlado/licenca-nao-controlada/isencao'),
            },
            {
                path: '/estabelecimento-nao-controlado/licenca-eventos/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '@/views/estabelecimento-nao-controlado/licenca-nao-controlada/isencao'),
            },

            {
                path: '/estabelecimento-nao-controlado/licenca-eventos/isencao/:id/view',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '@/views/estabelecimento-nao-controlado/licenca-nao-controlada/isencao'),
            },
            // dae manual
            {
                path: '/estabelecimento-nao-controlado/licenca-eventos/dae-manual/create',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '@/views/estabelecimento-nao-controlado/licenca-nao-controlada/daeManual'),
            },
            {
                path: '/estabelecimento-nao-controlado/licenca-eventos/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '@/views/estabelecimento-nao-controlado/licenca-nao-controlada/daeManual'),
            },
            {
                path: '/estabelecimento-nao-controlado/licenca-eventos/dae-manual/:id/view',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '@/views/estabelecimento-nao-controlado/licenca-nao-controlada/daeManual'),
            },
            // dae automatico
            {
                path: '/estabelecimento-nao-controlado/licenca-eventos/dae-automatico/create',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '@/views/estabelecimento-nao-controlado/licenca-nao-controlada/daeAutomatico'),
            },
            {
                path: '/estabelecimento-nao-controlado/licenca-eventos/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '@/views/estabelecimento-nao-controlado/licenca-nao-controlada/daeAutomatico'),
            },
            {
                path: '/estabelecimento-nao-controlado/licenca-eventos/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '@/views/estabelecimento-nao-controlado/licenca-nao-controlada/daeAutomatico'),
            },

            {
                path: '/pessoa-juridica/licenca/isencao-nao-controlada/:id/view',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '@/views/estabelecimento-nao-controlado/licenca-nao-controlada/isencao'),
            },

            {
                path: '/pessoa-fisica/licenca-nao-controlada/dae-manual/create',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '@/views/estabelecimento-nao-controlado/licenca-nao-controlada/daeManual'),
            },
            
            // fim das rotas de licença de eventos para pessoas jurídicas não monitoradas
            {
                path: '/pessoa-juridica/pesquisa',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '../views/pessoa-juridica'),
            },
            {
                path: '/pessoa-juridica',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '../views/pessoa-juridica/mosaico'),
            },
            {
                path: '/pessoa-juridica/create',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '../views/pessoa-juridica/create'),
            },
            {
                path: '/pessoa-juridica/:id/edit',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '../views/pessoa-juridica/create'),
            },
            {
                path: '/pessoa-juridica/dados-gerais/:id',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '../views/pessoa-juridica/dadosGerais'),
            },

            //Rotas para Estabelecimento Blaster
            {
                path: '/blaster-empresa/pesquisa',
                component: () => import(/* webpackChunkName: "blaster-empresa" */ '../views/pessoa-juridica'),
            },
            {
                path: '/blaster-empresa/create',
                component: () => import(/* webpackChunkName: "blaster-empresa" */ '../views/pessoa-juridica/create'),
            },
            {
                path: '/blaster-empresa/:id/edit',
                component: () => import(/* webpackChunkName: "blaster-empresa" */ '../views/pessoa-juridica/create'),
            },
            //Rotas para Laudo Blaster
            {
                path: '/blaster-laudo/pesquisa',
                component: () => import(/* webpackChunkName: "blaster-laudo" */ '../views/blaster/laudo'),
            },
            {
                path: '/blaster-laudo/create',
                component: () => import(/* webpackChunkName: "blaster-laudo" */ '../views/blaster/laudo/create'),
            },
            {
                path: '/blaster-laudo/:id/edit',
                component: () => import(/* webpackChunkName: "blaster-laudo" */ '../views/blaster/laudo/create'),
            },
            {
                path: '/blaster-laudo/:id/view',
                component: () => import(/* webpackChunkName: "blaster-laudo-vw" */ '../views/blaster/laudo/create'),
            },


            //Rotas Registro Estabelecimento convencional
            {
                path: '/registro',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/index'),
            },
            {
                path: '/registro/dae-automatico/create',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/daeAutomatico'),
            },
            {
                path: '/registro/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/daeAutomatico'),
            },
            {
                path: '/registro/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/daeAutomatico'),
            },
            {
                path: '/registro/dae-manual/create',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/daeManual'),
            },
            {
                path: '/registro/dae-manual/:id/view',
                component: () =>
                import( /* webpackChunkName: "registro" */ '../views/registro/daeManual'
                )
            },
            {
                path: '/registro/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/daeManual'),
            },
            {
                path: '/registro/isencao/create',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/isencao'),
            },
            {
                path: '/registro/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/isencao'),
            },
            {
                path: '/registro/isencao/:id/view',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/isencao'),
            },

            //Rotas Registro Estabelecimento BLASTER
            {
                path: '/blaster/registro-pj',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/index'),
            },
            {
                path: '/blaster/registro-pj/dae-automatico/create',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/daeAutomatico'),
            },
            {
                path: '/blaster/registro-pj/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/daeAutomatico'),
            },
            {
                path: '/blaster/registro-pj/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/daeAutomatico'),
            },
            {
                path: '/blaster/registro-pj/dae-manual/create',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/daeManual'),
            },
            {
                path: '/blaster/registro-pj/dae-manual/:id/view',
                component: () =>
                    import( /* webpackChunkName: "registro" */ '../views/registro/daeManual'
                        )
            },
            {
                path: '/blaster/registro-pj/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/daeManual'),
            },
            {
                path: '/blaster/registro-pj/isencao/create',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/isencao'),
            },
            {
                path: '/blaster/registro-pj/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/isencao'),
            },
            {
                path: '/blaster/registro-pj/isencao/:id/view',
                component: () => import(/* webpackChunkName: "registro" */ '../views/registro/isencao'),
            },
            // Fim das rotas para Registro de Estabelecimento Blaster

            //Rotas Alvará Estabelecimento BLASTER
            {
                path: '/blaster/alvara-pj',
                component: () => import(/* webpackChunkName: "alvara" */ '../views/alvara/index'),
            },
            {
                path: '/blaster/alvara-pj/dae-automatico/create',
                component: () => import(/* webpackChunkName: "alvara" */ '../views/alvara/daeAutomatico'),
            },
            {
                path: '/blaster/alvara-pj/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "alvara" */ '../views/alvara/daeAutomatico'),
            },
            {
                path: '/blaster/alvara-pj/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "alvara" */ '../views/alvara/daeAutomatico'),
            },
            {
                path: '/blaster/alvara-pj/dae-manual/create',
                component: () => import(/* webpackChunkName: "alvara" */ '../views/alvara/daeManual'),
            },
            {
                path: '/blaster/alvara-pj/dae-manual/:id/view',
                component: () =>
                    import( /* webpackChunkName: "alvara" */ '../views/alvara/daeManual'
                        )
            },
            {
                path: '/blaster/alvara-pj/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "alvara" */ '../views/alvara/daeManual'),
            },
            {
                path: '/blaster/alvara-pj/isencao/create',
                component: () => import(/* webpackChunkName: "alvara" */ '../views/alvara/isencao'),
            },
            {
                path: '/blaster/alvara-pj/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "alvara" */ '../views/alvara/isencao'),
            },
            {
                path: '/blaster/alvara-pj/isencao/:id/view',
                component: () => import(/* webpackChunkName: "alvara" */ '../views/alvara/isencao'),
            },
            // Fim das rotas para Alvara de Estabelecimento Blaster





            // Rotas para Notificação
            {
                path: '/notificacao',
                component: () => import(/* webpackChunkName: "notificacao" */ '../views/notificacao'),
            },
            {
                path: '/notificacao/create',
                component: () => import(/* webpackChunkName: "notificacao"*/ '../views/notificacao/create'),
            },
            {
                path: '/notificacao/:id/edit',
                component: () => import(/* webpackChunkName: "notificacao" */ '../views/notificacao/create'),
            },
            {
                path: '/notificacao/:id/view',
                component: () => import(/* webpackChunkName: "notificacao" */ '../views/notificacao/visualizar'),
            },
            // Fim das Rotas para Notificação
            //Rotas do Processo Administrativo Apuratório
            {
                path: '/paa',
                component: () => import(/* webpackChunkName: "paa" */ '../views/paa'),
            },
            {
                path: '/paa/create',
                component: () => import(/* webpackChunkName: "paa" */ '../views/paa/create'),
            },
            {
                path: '/paa/:id/edit',
                component: () => import(/* webpackChunkName: "paa" */ '../views/paa/create'),
            },
            {
                path: '/paa/:id/view',
                component: () => import(/* webpackChunkName: "paa" */ '../views/paa/create'),
            },
            //Rotas do Termo de Ajuste de Conduta
            {
                path: '/tac',
                component: () => import(/* webpackChunkName: "tac" */ '../views/tac'),
            },
            {
                path: '/tac/create',
                component: () => import(/* webpackChunkName: "tac" */ '../views/tac/create'),
            },
            {
                path: '/tac/:id/edit',
                component: () => import(/* webpackChunkName: "tac" */ '../views/tac/create'),
            },
            {
                path: '/tac/:id/view',
                component: () => import(/* webpackChunkName: "tac" */ '../views/tac/create'),
            },
            // Fim das Rotas do Termo de Ajuste de Conduta

            //Rotas Termo de Compromisso
            {
                path: 'termo-compromisso',
                component: () => import(/* webpackChunkName: "termo-compromisso" */ '../views/termo-compromisso'),
            },
            {
                path: 'termo-compromisso/create',
                component: () => import(/* webpackChunkName: "termo-compromisso" */ '../views/termo-compromisso/create'),
            },
            {
                path: 'termo-compromisso/:id/edit',
                component: () => import(/* webpackChunkName: "termo-compromisso" */ '../views/termo-compromisso/create'),
            },
            {
                path: 'termo-compromisso/:id/view',
                component: () => import(/* webpackChunkName: "termo-compromisso" */ '../views/termo-compromisso/create'),
            },
            //Fim das Rotas de Termo de compromisso

            //Rotas Vistoria Realização
            {
                path: 'vistoria-realizacao',
                component: () => import(/* webpackChunkName: "vistoria-realizacao" */ '../views/vistoriador/mosaico'),
            },
            {
                path: 'vistoria-realizacao/pesquisa',
                component: () => import(/* webpackChunkName: "vistoria-realizacao" */ '../views/vistoriador/vistoria-realizacao'),
            },
            {
                path: 'vistoria-realizacao/create',
                component: () => import(/* webpackChunkName: "vistoria-realizacao" */ '../views/vistoriador/vistoria-realizacao/create'),
            },
            {
                path: 'vistoria-realizacao/create/:ve_id/:pj_id',
                component: () => import(/* webpackChunkName: "vistoria-realizacao" */ '../views/vistoriador/vistoria-realizacao/create'),
            },
            {
                path: 'vistoria-realizacao/:id/edit',
                component: () => import(/* webpackChunkName: "vistoria-realizacao" */ '../views/vistoriador/vistoria-realizacao/create'),
            },
            {
                path: 'vistoria-realizacao/:id/view',
                component: () => import(/* webpackChunkName: "vistoria-realizacao" */ '../views/vistoriador/vistoria-realizacao/create'),
            },
            {
                path: 'vistoria-realizacao/mapa',
                component: () => import(/* webpackChunkName: "vistoria-realizacao" */ '../views/vistoriador/vistoria-realizacao/mapa'),
            },
            {
                path: 'vistoria-a-realizar/pesquisa',
                component: () => import(/* webpackChunkName: "vistoria-realizacao" */ '../views/vistoriador/vistoria-a-realizar/'),
            },

            //Fim das Rotas de VistoriaRealização

            //Rotas de Laudo de constatação sonora
            {
                path: 'constatacao-sonora/pesquisa',
                component: () => import(/* webpackChunkName: "constatacao-sonora" */ '../views/vistoriador/constatacao-sonora'),
            },
            {
                path: 'constatacao-sonora/create',
                component: () => import(/* webpackChunkName: "vistoria-realizacao" */ '../views/vistoriador/constatacao-sonora/create'),
            },
            {
                path: 'constatacao-sonora/:id/edit',
                component: () => import(/* webpackChunkName: "vistoria-realizacao" */ '../views/vistoriador/constatacao-sonora/create'),
            },
            {
                path: 'constatacao-sonora/:id/view',
                component: () => import(/* webpackChunkName: "vistoria-realizacao" */ '../views/vistoriador/constatacao-sonora/create'),
            },
            //Rotas para a licença ambiental
            {
                path: 'licenca-ambiental',
                component: () => import(/* webpackChunkName: "licenca-ambiental" */ '../views/licenca-ambiental'),
            },
            {
                path: 'licenca-ambiental/create',
                component: () => import(/* webpackChunkName: "licenca-ambiental" */ '../views/licenca-ambiental/create'),
            },
            {
                path: 'licenca-ambiental/:id/edit',
                component: () => import(/* webpackChunkName: "licenca-ambiental" */ '../views/licenca-ambiental/create'),
            },
            {
                path: 'licenca-ambiental/:id/view',
                component: () => import(/* webpackChunkName: "licenca-ambiental" */ '../views/licenca-ambiental/create'),
            },
            //fim das rotas para a licença ambietal

            // Rotas para Termo de Declaração
            {
                path: '/declaracao',
                component: () => import(/* webpackChunkName: "declaracao" */ '../views/declaracao'),
            },
            {
                path: '/declaracao/create',
                component: () => import(/* webpackChunkName: "declaracao" */ '../views/declaracao/create'),
            },
            {
                path: '/declaracao/:id/edit',
                component: () => import(/* webpackChunkName: "declaracao" */ '../views/declaracao/create'),
            },
            {
                path: '/declaracao/:id/view',
                component: () => import(/* webpackChunkName: "declaracao" */ '../views/declaracao/visualizar'),
            },
            // Fim das rotas para Termo de Declaração

            // Rotas para Ofícios de Estabelecimento
            {
                path: '/oficio',
                component: () => import(/* webpackChunkName: "oficio" */ '../views/oficio'),
            },
            {
                path: '/oficio/create',
                component: () => import(/* webpackChunkName: "oficio" */ '../views/oficio/create'),
            },
            {
                path: '/oficio/:id/edit',
                component: () => import(/* webpackChunkName: "oficio" */ '../views/oficio/create'),
            },
            {
                path: '/oficio/:id/view',
                component: () => import(/* webpackChunkName: "oficio" */ '../views/oficio/visualizar'),
            },
            // Fim das Rotas para Ofícios de Estabelecimento

            // Rotas para Ofícios de Blaster
            {
                path: '/blaster/oficio',
                component: () => import(/* webpackChunkName: "oficio" */ '../views/blaster/oficio'),
            },
            {
                path: '/blaster/oficio/edit/:id',
                component: () => import(/* webpackChunkName: "oficio" */ '../views/blaster/oficio/create'),
            },
            {
                path: '/blaster/oficio/edit/:id/view',
                component: () => import('../views/blaster/oficio/create'),
            },
            {
                path: '/blaster/oficio/create',
                component: () => import(/* webpackChunkName: "oficio" */ '../views/blaster/oficio/create'),
            },
            // Rotas para Licença de Bombeiros
            {
                path: '/bombeiro',
                component: () => import(/* webpackChunkName: "bombeiro" */ '../views/bombeiro'),
            },
            {
                path: '/bombeiro/create',
                component: () => import(/* webpackChunkName: "bombeiro" */ '../views/bombeiro/create'),
            },
            {
                path: '/bombeiro/:id/edit',
                component: () => import(/* webpackChunkName: "bombeiro" */ '../views/bombeiro/create'),
            },
            {
                path: '/bombeiro/:id/view',
                component: () => import(/* webpackChunkName: "bombeiro" */ '../views/bombeiro/visualizar'),
            },
            // Fim das Rotas para Licença de Bombeiros
            //Rotas para o Blaster
            {
                path: '/blaster',
                component: () => import(/* webpackChunkName: "blaster" */ '../views/blaster/mosaico')
            },
            {
                path: '/blaster/create',
                component: () => import(/* webpackChunkName: "blaster" */ '../views/blaster/create'),
            },
            {
                path: '/blaster/pesquisa',
                component: () => import(/* webpackChunkName: "blaster" */ '../views/blaster/index'),
            },
            {
                path: '/blaster/:id/edit',
                component: () => import(/* webpackChunkName: "blaster" */ '../views/blaster/create'),
            },
            {
                path: '/blaster/:id/view',
                component: () =>
                    import(/* webpackChunkName: "blaster" */ "../views/blaster/create"),
            },
            //Fim das Rotas para o Blaster
            //Rotas para Pirotecnia
            {
                path: '/pirotecnia/create',
                component: () => import(/* webpackChunkName: "pirotecnia" */ '../views/blaster/pirotecnia/create'),
            },
            {
                path: '/pirotecnia/pesquisa',
                component: () => import(/* webpackChunkName: "pirotecnia" */ '../views/blaster/pirotecnia/index'),
            },
            {
                path: '/pirotecnia/:id/edit',
                component: () => import(/* webpackChunkName: "pirotecnia" */ '../views/blaster/pirotecnia/create'),
            },
            {
                path: '/pirotecnia/:id/view',
                component: () => import(/* webpackChunkName: "pirotecnia-vw" */ '../views/blaster/pirotecnia/create'),
            },
            //Fim das rotas para pirotecnia
            // Rotas para Blaster - Carteira
            {
                path: '/blaster/carteira',
                component: () => import(/* webpackChunkName: "carteiraBlaster" */ '../views/blaster/carteira')
            },
            {
                path: '/blaster/carteira/dae-automatico/create',
                component: () => import(/* webpackChunkName: "carteiraBlaster" */ '../views/blaster/carteira/daeAutomatico')
            },
            {
                path: '/blaster/carteira/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "carteiraBlaster" */ '../views/blaster/carteira/daeAutomatico')
            },
            {
                path: '/blaster/carteira/dae-manual/create',
                component: () => import(/* webpackChunkName: "carteiraBlaster" */ '../views/blaster/carteira/daeManual')
            },
            {
                path: '/blaster/carteira/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "carteiraBlaster" */ '../views/blaster/carteira/daeManual')
            },
            {
                path: '/blaster/carteira/isencao/create',
                component: () => import(/* webpackChunkName: "carteiraBlaster" */ '../views/blaster/carteira/isencao')
            },
            {
                path: '/blaster/carteira/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "carteiraBlaster" */ '../views/blaster/carteira/isencao')
            },
            {
                path: '/blaster/carteira/dae-manual/:id/view',
                component: () =>
                    import(/* webpackChunkName: "carteiraBlaster" */ "../views/blaster/carteira/daeManual"),
            },
            {
                path: '/blaster/carteira/isencao/:id/view',
                component: () =>
                    import(/* webpackChunkName: "carteiraBlaster" */ "../views/blaster/carteira/isencao"),
            },
            {
                path: '/blaster/carteira/dae-automatico/:id/view',
                component: () =>
                    import(/* webpackChunkName: "carteiraBlaster" */ "../views/blaster/carteira/daeAutomatico"),
            },
            //    Fim das rotas para Blaster - Carteira
            {
                path: '/acao',
                component: () =>  import(/* webpackChunkName: "acoes" */ '../views/seguranca/acao/index'),
            },
            {
                path: '/acao/create',
                component: () =>  import(/* webpackChunkName: "acoes" */ '../views/seguranca/acao/create'),
            },
            {
                path: '/acao/:id/edit',
                component: () =>  import(/* webpackChunkName: "acoes" */ '../views/seguranca/acao/create'),
            },
            {
                path: '/perfil',
                component: () => import(/* webpackChunkName: "perfil" */ '../views/seguranca/perfil/index'),
            },
            {
                path: '/perfil/create',
                component: () => import(/* webpackChunkName: "perfil" */ '../views/seguranca/perfil/create'),
            },
            {
                path: '/perfil/:id/edit',
                component: () => import(/* webpackChunkName: "perfil" */ '../views/seguranca/perfil/create'),
            },
            {
                path: '/usuario',
                component: () => import(/* webpackChunkName: "usuario" */ '../views/usuario/index'),
            },
            {
                path: '/usuario/create',
                component: () => import(/* webpackChunkName: "usuario" */ '../views/usuario/create'),
            },
            {
                path: '/usuario/:id/edit',
                component: () => import(/* webpackChunkName: "usuario" */ '../views/usuario/create'),
            },
            {
                path: '/usuario/:id/view',
                component: () => import(/* webpackChunkName: "usuario" */ '../views/usuario/create'),
            },
            {
                path: '/configuracoes',
                component: () => import(/* webpackChunkName: "usuario" */ '../views/usuario/configuracoes'),
            },
            {
                path: '/configuracoes/senha',
                component: () => import(/* webpackChunkName: "usuario" */ '../views/usuario/senha'),
            },

            // Rotas de Licença de Eventos de Pessoa Juridica
            {
                path: '/pessoa-juridica/licenca',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '../views/pessoa-juridica/licenca'),
            },
            {
                path: '/pessoa-juridica/licenca/dae-automatico/create',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '../views/pessoa-juridica/licenca/daeAutomatico'),
            },
            {
                path: '/pessoa-juridica/licenca/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '../views/pessoa-juridica/licenca/daeAutomatico'),
            },
            {
                path: '/pessoa-juridica/licenca/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '../views/pessoa-juridica/licenca/daeAutomatico'),
            },
            {
                path: '/pessoa-juridica/licenca/dae-manual/create',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '../views/pessoa-juridica/licenca/daeManual'),
            },
            {
                path: '/pessoa-juridica/licenca/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '../views/pessoa-juridica/licenca/daeManual'),
            },
            {
                path: '/pessoa-juridica/licenca/dae-manual/:id/view',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '../views/pessoa-juridica/licenca/daeManual'),
            },
            {
                path: '/pessoa-juridica/licenca/isencao/create',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '../views/pessoa-juridica/licenca/isencao'),
            },
            {
                path: '/pessoa-juridica/licenca/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '../views/pessoa-juridica/licenca/isencao'),
            },
            {
                path: '/pessoa-juridica/licenca/isencao/:id/view',
                component: () => import(/* webpackChunkName: "pessoa-juridica-lf" */ '../views/pessoa-juridica/licenca/isencao'),
            },
            // Fim de Rotas de Licença de Eventos de Pessoa Física

            //Rotas Registro - Blaster
            {
                path: '/blaster/registro',
                component: () => import(/* webpackChunkName: "registro-blaster" */ '../views/blaster/registro/index'),
            },
            {
                path: '/blaster/registro/dae-automatico/create',
                component: () => import(/* webpackChunkName: "registro-blaster" */ '../views/blaster/registro/daeAutomatico'),
            },
            {
                path: '/blaster/registro/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "registro-blaster" */ '../views/blaster/registro/daeAutomatico'),
            },
            {
                path: '/blaster/registro/dae-manual/create',
                component: () => import(/* webpackChunkName: "registro-blaster" */ '../views/blaster/registro/daeManual'),
            },
            {
                path: '/blaster/registro/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "registro-blaster" */ '../views/blaster/registro/daeManual'),
            },
            {
                path: '/blaster/registro/isencao/create',
                component: () => import(/* webpackChunkName: "registro-blaster" */ '../views/blaster/registro/isencao'),
            },
            {
                path: '/blaster/registro/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "registro-blaster" */ '../views/blaster/registro/isencao'),
            },
            {
                path: '/blaster/registro/dae-manual/:id/view',
                component: () => import(/* webpackChunkName: "blaster-registro-dm" */ '../views/blaster/registro/daeManual'),
            },
            {
                path: '/blaster/registro/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "blaster-registro-da" */ '../views/blaster/registro/daeAutomatico'),
            },
            {
                path: '/blaster/registro/isencao/:id/view',
                component: () => import(/* webpackChunkName: "blaster-registro-is" */ '../views/blaster/registro/isencao'),
            },
            //Fim das Rotas Registro - Blaster
            /////INICIO ROTAS PESSOA JURÍDICA INTIMAÇÃO
            {
                path: '/pessoa-juridica/intimacao',
                component: () => import(/* webpackChunkName: "intimacao" */'../views/pessoa-juridica/intimacao'),
            },
            {
                path: '/pessoa-juridica/intimacao/:id/edit',
                component: () => import(/* webpackChunkName: "intimacao" */'../views/pessoa-juridica/intimacao/create'),
            },
            {
                path: '/pessoa-juridica/intimacao/:id/view',
                component: () => import(/* webpackChunkName: "intimacao" */'../views/pessoa-juridica/intimacao/create'),
            },
            {
                path: '/pessoa-juridica/intimacao/create',
                component: () => import(/* webpackChunkName: "intimacao" */'../views/pessoa-juridica/intimacao/create'),
            },
            /////FIM ROTAS PESSOA JURÍDICA INTIMAÇÃO
            //Rotas para procedimento policial (Antigo TCO)
            {
                path: '/pessoa-juridica/procedimento-policial',
                component: () => import(/* webpackChunkName: "procedimento-policial" */ '../views/pessoa-juridica/procedimento-policial'),
            },
            {
                path: '/pessoa-juridica/procedimento-policial/create',
                component: () => import(/* webpackChunkName: "procedimento-policial" */ '../views/pessoa-juridica/procedimento-policial/create'),
            },
            {
                path: '/pessoa-juridica/procedimento-policial/:id/edit',
                component: () => import(/* webpackChunkName: "procedimento-policial" */ '../views/pessoa-juridica/procedimento-policial/create'),
            },
            {
                path: '/pessoa-juridica/procedimento-policial/:id/view',
                component: () => import(/* webpackChunkName: "procedimento-policial" */ '../views/pessoa-juridica/procedimento-policial/create'),
            },
            //Fim das Rotas para procedimento policial (Antigo TCO)

            //Rota para Mosaico de Colete
            {
                path: '/colete',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/colete/mosaico.vue'),
            },
            {
                path: '/colete-empresa/pesquisa',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '../views/pessoa-juridica'),
            },
            {
                path: '/colete-empresa/create',
                component: () => import(/* webpackChunkName: "pessoa-juridica" */ '../views/pessoa-juridica/create'),
            },
            {
                path: '/colete-empresa/:id/edit',
                component: () => import(/* webpackChunkName: "colete-empresa" */ '../views/pessoa-juridica/create'),
            },


            //Rotas Certidão — Estabelecimento
            {
                path: '/certidao-pj',
                component: () => import(/* webpackChunkName: "certidaoPJ-grid" */ '@/views/certidao-pj/index'),
            },
            {
                path: '/certidao-pj/dae-automatico/create',
                component: () => import(/* webpackChunkName: "certidaoPJ-create-dae-automatico" */ '@/views/certidao-pj/daeAutomatico'),
            },
            {
                path: '/certidao-pj/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPJ-edit-dae-automatico" */ '@/views/certidao-pj/daeAutomatico'),
            },
            {
                path: '/certidao-pj/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPJ-view-dae-automatico" */ '@/views/certidao-pj/daeAutomatico'),
            },
            {
                path: '/certidao-pj/dae-manual/create',
                component: () => import(/* webpackChunkName: "certidaoPJ-create-dae-manual" */ '@/views/certidao-pj/daeManual'),
            },
            {
                path: '/certidao-pj/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPJ-edit-dae-manual" */ '@/views/certidao-pj/daeManual'),
            },
            {
                path: '/certidao-pj/dae-manual/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPJ-view-dae-manual" */ '@/views/certidao-pj/daeManual'),
            },
            {
                path: '/certidao-pj/isencao/create',
                component: () => import(/* webpackChunkName: "certidaoPJ-create-isencao" */ '@/views/certidao-pj/isencao'),
            },
            {
                path: '/certidao-pj/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPJ-edit-isencao" */ '@/views/certidao-pj/isencao'),
            },
            {
                path: '/certidao-pj/isencao/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPJ-view-isencao" */ '@/views/certidao-pj/isencao'),
            },
            //Fim das Rotas Certidão — Estabelecimento

            //Rotas Certidão — Pessoa Física
            {
                path: '/certidao-pf',
                component: () => import(/* webpackChunkName: "certidaoPF-grid" */ '@/views/certidao-pf/index'),
            },
            {
                path: '/certidao-pf/dae-automatico/create',
                component: () => import(/* webpackChunkName: "certidaoPF-create-dae-automatico" */ '@/views/certidao-pf/daeAutomatico'),
            },
            {
                path: '/certidao-pf/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPF-edit-dae-automatico" */ '@/views/certidao-pf/daeAutomatico'),
            },
            {
                path: '/certidao-pf/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPF-view-dae-automatico" */ '@/views/certidao-pf/daeAutomatico'),
            },
            {
                path: '/certidao-pf/dae-manual/create',
                component: () => import(/* webpackChunkName: "certidaoPF-create-dae-manual" */ '@/views/certidao-pf/daeManual'),
            },
            {
                path: '/certidao-pf/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPF-edit-dae-manual" */ '@/views/certidao-pf/daeManual'),
            },
            {
                path: '/certidao-pf/dae-manual/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPF-view-dae-manual" */ '@/views/certidao-pf/daeManual'),
            },
            {
                path: '/certidao-pf/isencao/create',
                component: () => import(/* webpackChunkName: "certidaoPF-create-isencao" */ '@/views/certidao-pf/isencao'),
            },
            {
                path: '/certidao-pf/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPF-edit-isencao" */ '@/views/certidao-pf/isencao'),
            },
            {
                path: '/certidao-pf/isencao/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPF-view-isencao" */ '@/views/certidao-pf/isencao'),
            },
            //Fim das Rotas Certidão — Pessoa Física


            //Rotas Certidão — Colete — Estabelecimento
            {
                path: '/colete/certidao-pj',
                component: () => import(/* webpackChunkName: "certidaoPJ-colete" */ '@/views/certidao-pj/index'),
            },
            {
                path: '/colete/certidao-pj/dae-automatico/create',
                component: () => import(/* webpackChunkName: "certidaoPJ-colete" */ '@/views/certidao-pj/daeAutomatico'),
            },
            {
                path: '/colete/certidao-pj/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPJ-colete" */ '@/views/certidao-pj/daeAutomatico'),
            },
            {
                path: '/colete/certidao-pj/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPJ-colete" */ '@/views/certidao-pj/daeAutomatico'),
            },
            {
                path: '/colete/certidao-pj/dae-manual/create',
                component: () => import(/* webpackChunkName: "certidaoPJ-colete" */ '@/views/certidao-pj/daeManual'),
            },
            {
                path: '/colete/certidao-pj/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPJ-colete" */ '@/views/certidao-pj/daeManual'),
            },
            {
                path: '/colete/certidao-pj/dae-manual/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPJ-colete" */ '@/views/certidao-pj/daeManual'),
            },
            {
                path: '/colete/certidao-pj/isencao/create',
                component: () => import(/* webpackChunkName: "certidaoPJ-colete" */ '@/views/certidao-pj/isencao'),
            },
            {
                path: '/colete/certidao-pj/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPJ-colete" */ '@/views/certidao-pj/isencao'),
            },
            {
                path: '/colete/certidao-pj/isencao/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPJ-colete" */ '@/views/certidao-pj/isencao'),
            },
            //Fim das Rotas Certidão — Colete

            //Rotas Dou — Colete
            {
                path: '/colete/dou/',
                component: () => import(/*webpackChunkName: "dou-colete" */ '../views/colete/dou'),
            },
            {
                path: '/colete/dou/create',
                component: () => import(/*webpackChunkName: "dou-colete" */ '../views/colete/dou/create'),
            },
            {
                path: '/colete/dou/:id/edit',
                component: () => import(/*webpackChunkName: "dou-colete" */ '../views/colete/dou/create'),
            },
            {
                path: '/colete/dou/:id/view',
                component: () => import(/*webpackChunkName: "dou-colete" */ '../views/colete/dou/create'),
            },
            //Fim das Rotas DOU — Colete

            //Rotas Certidão — Colete — Pessoa Física
            {
                path: '/colete/certidao-pf',
                component: () => import(/* webpackChunkName: "certidaoPF-colete" */ '@/views/certidao-pf/index'),
            },
            {
                path: '/colete/certidao-pf/dae-automatico/create',
                component: () => import(/* webpackChunkName: "certidaoPF-colete" */ '@/views/certidao-pf/daeAutomatico'),
            },
            {
                path: '/colete/certidao-pf/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPF-colete" */ '@/views/certidao-pf/daeAutomatico'),
            },
            {
                path: '/colete/certidao-pf/dae-manual/create',
                component: () => import(/* webpackChunkName: "certidaoPF-colete" */ '@/views/certidao-pf/daeManual'),
            },
            {
                path: '/colete/certidao-pf/dae-manual/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPF-colete" */ '@/views/certidao-pf/daeManual'),
            },
            {
                path: '/colete/certidao-pf/isencao/create',
                component: () => import(/* webpackChunkName: "certidaoPF-colete" */ '@/views/certidao-pf/isencao'),
            },
            {
                path: '/colete/certidao-pf/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "certidaoPF-colete" */ '@/views/certidao-pf/isencao'),
            },
            {
                path: '/colete/certidao-pf/isencao/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPF-colete-vw" */ '@/views/certidao-pf/isencao'),
            },
            {
                path: '/colete/certidao-pf/dae-manual/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPF-colete-vw" */ '@/views/certidao-pf/daeManual'),
            },
            {
                path: '/colete/certidao-pf/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "certidaoPF-colete-vw" */ '@/views/certidao-pf/daeAutomatico'),
            },
            //Fim das Rotas Certidão — Colete

            // Rotas de Pessoa Física para Colete
            {
                path: '/colete/pessoa-fisica/pesquisa',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica'),
            },
            {
                path: '/colete/pessoa-fisica/create',
                component: () => import(/* webpackChunkName: "pessoa-fisica" */ '../views/pessoa-fisica/create'),
            },
            // Fim das Rotas de Pessoa Física para Colete

            //Rotas de Colete - Laudo CPC
            {
                path: '/colete/cpc',
                component: () => import(/*webpackChunkName: "cpc-colete" */ '../views/colete/cpc'),
            },
            {
                path: '/colete/cpc/create',
                component: () => import(/*webpackChunkName: "cpc-colete" */ '../views/colete/cpc/create'),
            },
            {
                path: '/colete/cpc/:id/edit',
                component: () => import(/*webpackChunkName: "cpc-colete" */ '../views/colete/cpc/create'),
            },
            {
                path: '/colete/cpc/:id/view',
                component: () => import(/*webpackChunkName: "cpc-colete" */ '../views/colete/cpc/create'),
            },
            //Fim das Rotas de Colete - Laudo CPC

            //Rotas de Colete - Oficio PF
            {
                path: '/colete/oficio-pf/',
                component: () => import(/*webpackChunkName: "cpc-colete" */ '../views/colete/oficio-pf'),
            },
            {
                path: '/colete/oficio-pf/create',
                component: () => import(/*webpackChunkName: "cpc-colete" */ '../views/colete/oficio-pf/create'),
            },
            {
                path: '/colete/oficio-pf/:id/edit',
                component: () => import(/*webpackChunkName: "cpc-colete" */ '../views/colete/oficio-pf/create'),
            },
            {
                path: '/colete/oficio-pf/:id/view',
                component: () => import(/*webpackChunkName: "cpc-colete-vw" */ '../views/colete/oficio-pf/create'),
            },
            //Fim das Rotas de Colete - Oficio PF

            //Rotas de Colete -Nota Fisscal de Compra
            {
                path: '/colete/nota-fiscal-compra/',
                component: () => import(/*webpackChunkName: "colete-nota-fiscal-compra" */ '../views/colete/nota-fiscal-compra'),
            },
            {
                path: '/colete/nota-fiscal-compra/create',
                component: () => import(/*webpackChunkName: "colete-nota-fiscal-compra" */ '../views/colete/nota-fiscal-compra/create'),
            },
            {
                path: '/colete/nota-fiscal-compra/:id/edit',
                component: () => import(/*webpackChunkName: "colete-nota-fiscal-compra" */ '../views/colete/nota-fiscal-compra/create'),
            },
            {
                path: '/colete/nota-fiscal-compra/:id/view',
                component: () => import(/*webpackChunkName: "colete-nota-fiscal-compra-vw" */ '../views/colete/nota-fiscal-compra/create'),
            },
            //Fim das Rotas de Colete - Nota Fiscal de Compra

            //Rotas de Colete - Estabelecimento de Fabricação e venda
            {
                path: '/colete/empresa-fabricacao-venda/',
                component: () => import(/*webpackChunkName: "colete-empresa-fabricacao-venda" */ '../views/colete/empresa-fabricacao-venda'),
            },
            {
                path: '/colete/empresa-fabricacao-venda/create',
                component: () => import(/*webpackChunkName: "colete-empresa-fabricacao-venda" */ '../views/colete/empresa-fabricacao-venda/create'),
            },
            {
                path: '/colete/empresa-fabricacao-venda/:id/edit',
                component: () => import(/*webpackChunkName: "colete-nempresa-fabricacao-venda" */ '../views/colete/empresa-fabricacao-venda/create'),
            },
            {
                path: '/colete/empresa-fabricacao-venda/:id/view',
                component: () => import(/*webpackChunkName: "colete-nempresa-fabricacao-venda" */ '../views/colete/empresa-fabricacao-venda/create'),
            },
            //Fim das Rotas de Colete - Estabelecimento de Fabricação e venda

            // Rotas de Multas
            {
                path:'/pessoa-juridica/multa',
                component: () => import(/*webpackChunkName: "multa" */ '../views/pessoa-juridica/multa'),
            },
            {
                path:'/pessoa-juridica/multa/create',
                component: () => import(/*webpackChunkName: "multa" */ '../views/pessoa-juridica/multa/create'),
            },
            {
                path: '/pessoa-juridica/multa/:id/edit',
                component: () => import(/*webpackChunkName: "multa" */ '../views/pessoa-juridica/multa/create'),
            },
            {
                path: '/pessoa-juridica/multa/:id/view',
                component: () => import(/*webpackChunkName: "multa" */ '../views/pessoa-juridica/multa/create'),
            },
            // Fim das Rotas de Multas

            //ROTAS DE RELATÓRIOS
            {
                path:'/relatorios',
                component:() => import(/*webpackChunkName: "relatorios" */ '../views/relatorios/mosaico.vue'),
            },
            //Estabelecimento
            {
                path:'/relatorios/pessoa-juridica',
                component:() => import(/*webpackChunkName: "relatorios" */ '../views/relatorios/pessoa-juridica'),
            },
            //blaster
            {
                path:'/relatorios/blaster',
                component:() => import(/*webpackChunkName: "relatorios" */ '../views/relatorios/blaster'),
            },
            {
                path:'/relatorios/colete',
                component:() => import(/*webpackChunkName: "relatorios-colete" */ '../views/relatorios/colete'),
            },
            //Pessoa Física
            {
                path:'/relatorios/pessoa-fisica',
                component:() => import(/*webpackChunkName: "relatorios" */ '../views/relatorios/pessoa-fisica')
            },
            //mapa de arrecadação
            {
                path:'/relatorios/mapa-arrecadacao',
                component:() => import(/*webpackChunkName: "relatorios" */ '../views/relatorios/mapa-arrecadacao')
            },
            // Estatística de Vistoriador
            {
                path:'/relatorios/estatistica-vistoriador/grid',
                component: () => import (/*webpackChunkName: "Relatorio-Vistoriador" */ '../views/relatorios/vistoriador-relatorio.vue')
            },
            //FIM DAS ROTAS DE RELATÓRIOS

            //ROTAS PARA REGISTRO DE COLETE
            {
                path: '/colete-registro',
                component: () => import(/* webpackChunkName: "colete-registro" */ '../views/colete/registro'),
            },
            {
                path: '/colete-registro/dae-automatico/create',
                component: () => import(/* webpackChunkName: "colete-registro" */ "../views/colete/registro/daeAutomatico"),
            },
            {
                path: '/colete-registro/dae-automatico/:id/edit',
                component: () => import(/* webpackChunkName: "colete-registro" */ "../views/colete/registro/daeAutomatico"),
            },
            {
                path: '/colete-registro/dae-automatico/:id/view',
                component: () => import(/* webpackChunkName: "colete-registro" */ "../views/colete/registro/daeAutomatico"),
            },
            {
                path: "/colete-registro/dae-manual/create",
                component: () => import(/* webpackChunkName: "colete-registro" */ "../views/colete/registro/daeManual"),
            },
            {
                path: "/colete-registro/dae-manual/:id/edit",
                component: () => import(/* webpackChunkName: "colete-registro" */ "../views/colete/registro/daeManual"),
            },
            {
                path: "/colete-registro/dae-manual/:id/view",
                component: () => import(/* webpackChunkName: "colete-registro" */ "../views/colete/registro/daeManual"),
            },
            {
                path: "/colete-registro/isencao/create",
                component: () => import(/* webpackChunkName: "colete-registro"*/ "../views/colete/registro/isencao"),
            },
            {
                path: '/colete-registro/isencao/:id/edit',
                component: () => import(/* webpackChunkName: "colete-registro" */ "../views/colete/registro/isencao"),
            },
            {
                path: '/colete-registro/isencao/:id/view',
                component: () => import(/* webpackChunkName: "colete-registro" */ "../views/colete/registro/isencao"),
            },
            /////INICIO ROTAS COLETE OFICIO
            {
                path: '/colete/oficio',
                component: () => import(/* webpackChunkName: "colete-oficio" */'../views/colete/oficio'),
            },
            {
                path: '/colete/oficio/create/:id',
                component: () => import(/* webpackChunkName: "colete-oficio" */'../views/colete/oficio/create'),
            },
            {
                path: '/colete/oficio/:id/view',
                component: () => import(/* webpackChunkName: "colete-oficio" */'../views/colete/oficio/create'),
            },
            {
                path: '/colete/oficio/create',
                component: () => import(/* webpackChunkName: "colete-oficio" */'../views/colete/oficio/create'),
            },
            /////FIM ROTAS COLETE OFICIO

            /////INICIO ROTAS CERTIFICADO EXERCITO
            {
                path: '/colete/certificado-exercito',
                component: () => import(/* webpackChunkName: "colete-exercito" */'../views/colete/certificado-exercito'),
            },
            {
                path: '/colete/certificado-exercito/:id/edit',
                component: () => import(/* webpackChunkName: "colete-exercito" */'../views/colete/certificado-exercito/create'),
            },
            {
                path: '/colete/certificado-exercito/:id/view',
                component: () => import(/* webpackChunkName: "colete-exercito" */'../views/colete/certificado-exercito/create'),
            },
            {
                path: '/colete/certificado-exercito/create',
                component: () => import(/* webpackChunkName: "colete-exercito" */'../views/colete/certificado-exercito/create'),
            },
            /////FIM ROTAS CERTIFICADO EXERCITO
            /////INICIO ROTAS ORÇAMENTO
            {
                path: '/colete/orcamento',
                component: () => import(/* webpackChunkName: "orcamento" */'../views/colete/orcamento'),
            },
            {
                path: '/colete/orcamento/:id/edit',
                component: () => import(/* webpackChunkName: "orcamento" */'../views/colete/orcamento/create'),
            },
            {
                path: '/colete/orcamento/:id/view',
                component: () => import(/* webpackChunkName: "orcamento" */'../views/colete/orcamento/create'),
            },
            {
                path: '/colete/orcamento/create',
                component: () => import(/* webpackChunkName: "orcamento" */'../views/colete/orcamento/create'),
            },
            /////FIM ROTAS ORÇAMENTO

            // ROTAS PARA ALVARÁ DE LOCALIZAÇÃO DA PREFEITURA
            {
                path: '/alvara-localizacao',
                component: () => import(/* webpackChunkName: "orcamento" */'../views/pessoa-juridica/alvara-localizacao'),
            },
            {
                path: '/alvara-localizacao/:id/edit',
                component: () => import(/* webpackChunkName: "orcamento" */'../views/pessoa-juridica/alvara-localizacao/create'),
            },
            {
                path: '/alvara-localizacao/:id/view',
                component: () => import(/* webpackChunkName: "orcamento" */'../views/pessoa-juridica/alvara-localizacao/create'),
            },
            {
                path: '/alvara-localizacao/create',
                component: () => import(/* webpackChunkName: "orcamento" */'../views/pessoa-juridica/alvara-localizacao/create'),
            },
            // FIM DAS ROTAS PARA ALVARÁ DE LOCALIZAÇÃO DA PREFEITURA

            // ROTAS DE PARÂMETROS
            {
                path: '/parametros',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/mosaico'),
            },

            /** parâmetros diversos */
            {
                path: '/parametros/artigo-trinta-e-cinco/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/tipo-de-evento/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/tipo-de-cnpj/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/tipo-de-licenca/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/tipo-de-vistoria/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/atividade-solicitada/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/tipo-de-cobertura/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/tipo-de-construcao/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/situacao-de-blaster/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/secao-de-estabelecimento/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/situacao-de-estabelecimento/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/status/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/status-de-autorizacao/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/status-de-blaster/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },
            {
                path: '/parametros/tipo-de-licenca-ambiental/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/diversos/'),
            },

            /** Tipo Ofício */
            {
                path: '/parametros/tipo-oficio/',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/tipo-oficio/'),
            },
            {
                path: '/parametros/tipo-oficio/create',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/tipo-oficio/create'),
            },
            {
                path: '/parametros/tipo-oficio/:id/edit',
                component: () => import (/* webpackChunkName: "parametros" */ '../views/parametros/tipo-oficio/create'),
            },

            /** Classificação SEFA */
            {
                path: '/parametros/sefa',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/classificacao-sefa'),
            },
            {
                path: '/parametros/sefa/create',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/classificacao-sefa/create'),
            },
            {
                path: 'parametros/sefa/:id/edit',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/classificacao-sefa/create'),
            },
            {
                path: 'parametros/sefa/:id/view',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/classificacao-sefa/create'),
            },
            /** Validade Alvará */
            {
                path: '/parametros/validade-alvara',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/validade-alvara'),
            },
            {
                path: '/parametros/validade-alvara/create',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/validade-alvara/create'),
            },
            {
                path: 'parametros/validade-alvara/:id/edit',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/validade-alvara/create'),
            },
            {
                path: 'parametros/validade-alvara/:id/view',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/validade-alvara/create'),
            },
            /** Artigos */
            {
                path: '/parametros/artigos',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/artigos'),
            },
            {
                path: '/parametros/artigos/create',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/artigos/create'),
            },
            {
                path: 'parametros/artigos/:id/edit',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/artigos/create'),
            },
            {
                path: 'parametros/artigos/:id/view',
                component: () => import(/* webpackChunkName: "parametros" */ '../views/parametros/artigos/create'),
            },
            // FIM DAS ROTAS DE PARÂMETROS
            {
                path: '/tela',
                component: () => import(/* webpackChunkName: "tela" */'../views/seguranca/tela/index'),
                param: {
                    seguranca: true
                }
            }
        ],
    },

    {
        path: '/autenticacao',
        component: EstiloPublico,
        children: [
            {
                path: '',
                component: TelaLogin,
            },
            {
                path: '/sem-permissao',
                component: SemPermissao
            },
            {
                path: '/solicitar-acesso',
                component: () => import(/*webpackChunkName: "acesso" */ '../views/usuario/solicitar-acesso'),
            },
            {
                path: '/atualizar-senha',
                component: () => import(/*webpackChunkName: "atualizar-senha" */ '../views/site/atualizar-senha'),
            }
        ],
    },
    {
        path: '/:catchAll(.*)',
        component: HTML_404,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    //quem não está autenticado volta para a tela de login
    if (!isUsuarioAutenticado() && (to.path !== '/autenticacao' && to.path !== '/atualizar-senha')) {
        next({ path: '/autenticacao' })
    } else if (!isUsuarioAutenticado() && to.path === '/autenticado') {
        next();
    } else if (possuiPermissao(to.path)) {
        next();
    } else {
        alert('Sem permissão de acesso!')
    }
});

export default router;
